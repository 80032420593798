import React, { useEffect, useContext } from "react";
import { navigate } from "gatsby";
import BlockText from "./BlockText";
import CloseButton from "./CloseButton";
import { FigContext } from "../context/FigContext";

import * as styles from "./SubPage.module.css";

const SubPage = ({ title, _rawExcerpt }) => {
  const { closePageSteps } = useContext(FigContext);
  useEffect(() => {
    document.body.classList.add("sub-page");
    return () => {
      document.body.classList.remove("sub-page");
    };
  }, []);

  return (
    <div className={styles.root}>
      <span className={styles.buttonWrapper}>
        <CloseButton white onClick={() => navigate(closePageSteps ? -1 : "/")} />
      </span>
      <div className={styles.textWrapper}>
        {title && <p className={styles.title}>{title}</p>}
        {_rawExcerpt && <BlockText blocks={_rawExcerpt || []} />}
      </div>
    </div>
  );
};

export default SubPage;
