import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/GraphQLErrorList";
import SEO from "../components/SEO";
import SubPage from "../components/SubPage";

export const query = graphql`
  query SubPageTemplateQuery($id: String!) {
    subPage: sanityContact(id: { eq: $id }) {
      title
      _rawExcerpt
      slug {
        current
      }
    }
  }
`;

const SubPageTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.subPage;
  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {page && <SEO title={page.title} />}

      {errors && <GraphQLErrorList errors={errors} />}
      {page && <SubPage {...page} />}
    </>
  );
};

export default SubPageTemplate;
